import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/Seo";

import sofia from "../assets/images/artists/sofia/profile/sofia-artist-profile.jpg";
import sofia001 from "../assets/images/artists/sofia/work/sofia-001.jpg";
import sofia002 from "../assets/images/artists/sofia/work/sofia-002.jpg";
import sofia003 from "../assets/images/artists/sofia/work/sofia-003.jpg";
import sofia004 from "../assets/images/artists/sofia/work/sofia-004.jpg";
const Artist_Sofia = props => (
  <Layout>
    <SEO
      title="Artist Sofia Ripper"
      keywords={[`obsidian la`, `obsidian la tattoo`, `los angeles tattoo`]}
    />

    <div id="main" className="alt">
      <div className="inner">
        <header className="major">
          <h1>Sofia Ripper</h1>
        </header>
        <div className="image">
          <img src={sofia} alt="sofia ripper" className="image left" />
          <p>
            Sofia (@_sofiaripper_) was born and raised in Orange County and has
            always been art focused. She recently graduated from college where
            she studied printmaking and is currently mentored by Adam Vu Noir
            (@adamvunoir). Sofia practices tattooing on kewpie dolls which also
            influences her work. She draws inspiration from classical tattoo
            imagery and the fine line black and grey style.
          </p>
          <hr />
          <h4 style={{ float: `left` }}>
            <a
              href="https://www.instagram.com/_sofiaripper_/?hl=en"
              className="icon alt fa-instagram"
            >
              <span className="label">Instagram</span>
              &nbsp;@_sofiaripper_
            </a>
          </h4>

          <ul className="actions" style={{ float: `right` }}>
            <li>
              <a href="#contact" className="button next left scrolly fit">
                Booking
              </a>
            </li>
          </ul>
        </div>
      </div>
      <section
        className="tiles"
        style={{ width: `100%`, justifyContent: `center`, margin: `-1em` }}
      >
        <article
          style={{
            backgroundImage: `url(${sofia003})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${sofia001})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${sofia002})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
        <article
          style={{
            backgroundImage: `url(${sofia004})`,
            backgroundSize: `cover`,
            margin: `1em`,
            width: `25%`
          }}
        ></article>
      </section>
    </div>
  </Layout>
);
// export const query = graphql`
// query ArtistSofia {
//     allInstaNode (limit: 15) {
//         edges {
//             node {
//             id
//             likes
//             original
//             localFile{
//                 childImageSharp {
//                     fluid(maxHeight: 293, maxWidth: 293, quality: 50) {
//                         ...GatsbyImageSharpFluid_withWebp_tracedSVG
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
// `

export default Artist_Sofia;
